export const WORDS = [
  'banks',
  'debit',
  'loans',
  'money',
  'asset',
  'price',
  'saves',
  'spend',
  'stock',
  'rates',
  'value',
  'trade',
  'hedge',
  'funds',
  'bonds',
  'bills',
  'cents',
  'coins',
  'goods',
  'items',
  'order',
  'quote',
  'yield',
  'worth',
  'debts',
  'gross',
  'taxes',
  'lends',
  'raise',
  'rents',
  'sells',
  'wages',
  'bucks',
  'dimes',
  'gains',
  'penny',
  'notes',
  'heist',
]
